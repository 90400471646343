/* Стили для формы с координатами, размещенной по центру вверху */
/* Стили для формы с координатами, размещенной по центру вверху */
#coordinates-form {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 1001;
}

#coordinates-input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 300px; /* Увеличена ширина */
    height: 48px; /* Высота для поля ввода */
}

#add-coordinates-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.5rem;
    height: 48px; /* Высота, равная полю ввода */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px; /* Ширина кнопки "+" */
}

#add-coordinates-btn i {
    font-size: 1.5rem;
}

#add-coordinates-btn:hover {
    background-color: #0056b3;
}


/* Стили для кнопки "Поиск" */
#search-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.5rem;
    height: 48px; /* Высота, равная полю ввода */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px; /* Ширина кнопки */
    margin-left: 10px; /* Отступ между полем ввода и кнопкой */
}

#search-btn i {
    font-size: 1.5rem;
}

#search-btn:hover {
    background-color: #0056b3;
}
