#coordinates-form {
  z-index: 1001;
  align-items: center;
  gap: 10px;
  display: flex;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

#coordinates-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px;
  height: 48px;
  padding: 10px;
  font-size: 1rem;
}

#add-coordinates-btn {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 10px;
  font-size: 1.5rem;
  display: flex;
}

#add-coordinates-btn i {
  font-size: 1.5rem;
}

#add-coordinates-btn:hover {
  background-color: #0056b3;
}

#search-btn {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-left: 10px;
  padding: 10px;
  font-size: 1.5rem;
  display: flex;
}

#search-btn i {
  font-size: 1.5rem;
}

#search-btn:hover {
  background-color: #0056b3;
}
/*# sourceMappingURL=index.4f1d7018.css.map */
